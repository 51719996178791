<template>
  <div>
    <b-card
        no-body
        class="overflow-hidden p-1"
        @mouseover="isHovered=true"
        @mouseleave="isHovered=false"
        @click="$emit('click')"
    >
      <div class="d-flex">
        <div class="w-100">
          <!--          Line 1-->
          <div class="d-flex justify-content-between">
            <div
                class="d-flex"
                :style="'width: calc(98% - '+iconsWidth+')'"
            >
              <div v-if="isSelectable">
                <b-form-checkbox
                    v-model="check"
                />
              </div>
              <div>
                <icon
                    :icon="icon.icon"
                    class="mx-50"
                />
              </div>
              <!--              <div class="text-truncate" style="max-width: 14vw">-->
              <div class="text-truncate">
                <b>{{ reminder.title }}</b>
              </div>
              <div
                  v-if="reminder.completionDate != null && reminder.isClosed == false"
                  class="ml-50"
              >
                <b-badge :variant="daysFromNow.color">
                  {{ daysFromNow.text }}
                </b-badge>
              </div>
              <div v-if="reminder.isClosed == true" class="ml-1">
                <b-badge variant="secondary">
                  <i>{{ capitalize($t('closedReminder')) }}</i>
                </b-badge>
              </div>
            </div>

            <div
                class="my-auto d-flex justify-content-end"
                :style="'width: '+iconsWidth+ ';'"
            >
              <icon
                  v-if="reminder.documents.length > 0"
                  icon="paperclip"
                  class="mx-50"
              />
              <icon
                  v-if="reminder.notes != null"
                  icon="sticky-note"
                  class="mx-50"
              />
              <icon
                  v-if="reminder.steps.length > 0"
                  icon="list-ul"
                  class="mx-50"
              />
              <icon
                  v-if="!isHistory && (reminder.workFlo != null || reminder.workflow != null )"
                  icon="th-list"
                  class="mx-50"
              />
            </div>
          </div>

          <!--          Line 2-->
          <div
              v-if="!isHistory"
              class="d-flex mt-1"
          >
            <div
                v-if="reminder.workFlo != null"
                class="d-flex w-100"
            >
              <div>
                <b-badge
                    variant="light-primary"
                    class="text-truncate fill"
                    v-b-tooltip.hover.top="reminder.workFlo._display"
                >
                  {{ capitalize($t('workFlo')) }}
                </b-badge>
              </div>

              <div
                  v-b-tooltip.hover.top="reminder.workFlo._display"
                  class="ml-50 w-100 text-truncate"
              >
                {{ reminder.workFlo._display }}

                <icon
                    icon="external-link-alt"
                    class="ml-50"

                    @click="$router.push({name: 'WorkFlo view', params: { workFloId: reminder.workFlo.id }})"
                />
              </div>

            </div>

            <div
                v-else-if="reminder.workflow != null"
                class="d-flex w-100"
            >
              <div>
                <b-badge
                    variant="light-info"
                    class="text-truncate fill"
                    v-b-tooltip.hover.top="reminder.workflow.opportunity"
                >
                  {{ capitalize($t('workflow')) }}
                </b-badge>
              </div>

              <div
                  v-b-tooltip.hover.top="reminder.workflow.opportunity"
                  class="ml-50 w-100 text-truncate"
              >
                {{ reminder.workflow.opportunity }}

                <icon
                    icon="external-link-alt"
                    class="ml-50"

                    @click="$router.push({name: 'Workflow view', params: { workflowId: reminder.workflow.id }})"
                />
              </div>


            </div>
          </div>

          <!--          Line 3-->
          <div
              class="d-flex mt-1"
              :class="{'justify-content-between w-100': !isHistory}"
          >
            <div
                class="d-flex"
                :style="'max-width: '+maxUsersWidth+';'"
            >
              <b-avatar
                  v-if="reminder._createdBy != null && reminder._createdBy.avatar"
                  class="pull-up"
                  :src="reminder._createdBy.avatar.url"
                  variant="light-primary"
                  size="sm"
              />
              <b-avatar
                  v-else
                  class="pull-up"
                  :text="reminder._createdBy!=null?initials(reminder._createdBy._display):'?'"
                  variant="light-primary"
                  size="sm"
              />

              <div class="ml-50 text-truncate">
                {{ reminder._createdBy != null ? reminder._createdBy._display : '?' }}
              </div>
            </div>

            <div
                v-if="!isHistory && reminder.completionDate != null"
                ref="reminderCardDateRef"
                class="d-flex ml-50 text-muted text-nowrap"
            >
              <icon
                  icon="calendar-day"
                  class="mr-50"
              />
              {{ reminder.completionDate|moment('D MMM') }}
            </div>

            <div
                ref="reminderCardPriorityRef"
                class="d-flex"
                :class="isHistory?'ml-4':'ml-50'"
            >
              <b-badge
                  pill
                  :variant="currentBadgeColor"
                  class="mr-50"
              ></b-badge>
              <div class="ml-50">
                <b>{{ capitalize($t(reminder.priority)) }}</b>
              </div>
            </div>

            <div
                v-if="isHistory && displayLastUpdate != null"
                class="d-flex text-secondary text-nowrap ml-4"
            >
              <icon icon="history"/>

              <div class="ml-50">
                <small>
                  <i>{{ displayLastUpdate }}</i>
                </small>
              </div>
            </div>

          </div>

          <!--          Line 4-->
          <div
              v-if="reminder.notes != null"
              class="d-flex mt-1"
          >
            <v-clamp
                autoresize
                :max-lines="3"
            >
              {{ escapeHtml(reminder.notes) }}
              <template #after="{ toggle, clamped }">
                <span
                    v-if="clamped == true"
                    @click="toggle"
                    class="ml-50 text-primary cursor-pointer"
                >
                  <b>
                    <i>{{ capitalize($t('viewAll')) }}</i>
                  </b>
                </span>
              </template>
            </v-clamp>
          </div>
        </div>

        <Transition name="slide">
          <div
              v-if="isHovered && (isEditable || isDeletable)"
              class="my-auto d-flex"
          >
            <edit-button
                @click.native.stop="$emit('edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :disabled="!isEditable"
                size="sm"
            />

            <remove-button
                @click.native.stop="$emit('delete')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :is-disabled="!isDeletable"
                size="sm"
            />
          </div>
        </Transition>
      </div>

    </b-card>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import ContentWorkFlo           from './components/WorkFloContent.vue'
import GenericCard              from './Card.vue'
import moment                   from 'moment'
import i18n                     from '../../libs/i18n'
import { capitalize }           from '../../utils/filter'
import { initials, escapeHtml } from '../../utils/utils'
import EditButton               from '../button/Edit.vue'
import RemoveButton             from '../button/Remove.vue'
import VClamp                   from 'vue-clamp'

export default {
  components: { VClamp, GenericCard, ContentWorkFlo, EditButton, RemoveButton },
  props: {
    reminder: {
      type: Object,
      default: () => {}
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(props.reminder)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const check = ref(false)
    const isHovered = ref(false)

    const reminderCardDateRef = ref()
    const reminderCardPriorityRef = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const daysFromNow = computed(() => {
      let days = moment(props.reminder.completionDate).diff(moment(), 'days')

      return {
        days: days,
        color: days < 0 ? 'danger' : (days == 0 ? 'secondary' : 'success'),
        text: days < 0 ? (i18n.tc('xDaysLate', -days, { days: -days })) : (i18n.tc('xDaysRemaining', days, { days: days }))
      }
    })

    const currentBadgeColor = computed(() => {
      if (props.reminder.priority == 'high') {
        return 'danger'
      } else if (props.reminder.priority == 'medium') {
        return 'warning'
      } else {
        return 'success'
      }
    })

    const iconsWidth = computed(() => {
      let output = 0
      let iconWidth = 28

      if (props.reminder.documents.length > 0) {
        output += iconWidth
      }
      if (props.reminder.notes != null) {
        output += iconWidth
      }
      if (props.reminder.steps.length > 0) {
        output += iconWidth
      }
      if (props.reminder.workFlo != null || props.reminder.workflow != null) {
        output += iconWidth
      }

      return output + 'px'
    })

    const maxUsersWidth = computed(() => {
      if (reminderCardDateRef.value != undefined && reminderCardPriorityRef.value != undefined) {
        let width = reminderCardDateRef.value.offsetWidth + reminderCardPriorityRef.value.offsetWidth
        return 'calc(90% - ' + width + 'px)'
      } else {
        return '100%'
      }

    })

    const icon = computed(() => {
      if (props.reminder.type == 'discussion') {
        return {
          icon: 'comments',
          library: 'fas'
        }
      } else if (props.reminder.type == 'call') {
        return {
          icon: 'phone',
          library: 'fas'
        }
      } else if (props.reminder.type == 'mail') {
        return {
          icon: 'envelope',
          library: 'fas'
        }
      } else if (props.reminder.type == 'intervention') {
        return {
          icon: 'briefcase',
          library: 'fas'
        }
      } else if (props.reminder.type == 'trip') {
        return {
          icon: 'suitcase-rolling',
          library: 'fas'
        }
      } else if (props.reminder.type == 'absence') {
        return {
          icon: 'glasses',
          library: 'fas'
        }
      } else {
        return {
          icon: 'user-circle',
          library: 'fas'
        }
      }
    })

    const lastUpdate = computed(() => {
      if (props.reminder.logs.length == 0) {
        return null
      } else {
        return props.reminder.logs.slice(-1)[0]
      }
    })

    const displayLastUpdate = computed(() => {
      if (lastUpdate == null || lastUpdate.value.action == 'create') {
        return null
      } else {
        return capitalize(i18n.t('lastUpdateDate', { 'date': moment(lastUpdate.value.at).fromNow() }))
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(check, val => {
      emit('checkReminder')
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      escapeHtml,

      // Data
      check,
      isHovered,
      reminderCardDateRef,
      reminderCardPriorityRef,

      // Computed
      daysFromNow,
      currentBadgeColor,
      iconsWidth,
      maxUsersWidth,
      icon,
      displayLastUpdate,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { initials, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
.badge:empty {
  display: block;
  width: 20px;
  height: 20px;
}
</style>