import { computed, onMounted, ref, watch }   from '@vue/composition-api'
import { dom }                               from '@fortawesome/fontawesome-svg-core'
import { clone, getEventIcons, getUserData } from '../../utils/utils'

import dayGridPlugin     from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin    from '@fullcalendar/timegrid'
import listPlugin        from '@fullcalendar/list'
import store             from '../../store'
import Vue               from 'vue'
import i18n              from '../../libs/i18n'
import moment            from 'moment'
import router            from '../../router'
import { capitalize }    from '../../utils/filter'

export const useCalendar = (emit) => {
  dom.watch()

  // ------------------------------------------------
  // UI
  // ------------------------------------------------
  const isCalendarOverlaySidebarActive = ref(false)
  const isEventHandlerSidebarActive = ref(false)
  const viewDates = ref(null)

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    emit('fetchEvents', selectedCalendars.value, info, successCallback)
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: ['home', 'home2'].includes(router.history.current.name) ? 'timeGridWeek' : 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle,prev,customToday,next,title',
      end: 'dayGridMonth,timeGridWeek,timeGridThreeDays,timeGridDay,listMonth',
    },
    buttonText: {
      today: i18n.t('today'),
      month: i18n.t('month'),
      week: i18n.t('week'),
      day: i18n.tc('day'),
      list: i18n.t('list'),
      timeGridThreeDays: '3 ' + i18n.tc('day', 3)
    },
    customButtons: {
      customToday: {
        text: '🏠',
        click: function () {
          calendarApi.today()
        }
      }
    },
    views: {
      timeGridThreeDays: {
        type: 'timeGrid',
        duration: { days: 3 }
      }
    },
    businessHours: [
      {
        daysOfWeek: [1, 2, 3, 4, 5],
        startTime: '09:00',
        endTime: '12:00'
      },
      {
        daysOfWeek: [1, 2, 3, 4, 5],
        startTime: '14:00',
        endTime: '18:00'
      },
    ],
    events: fetchEvents,
    allDayText: i18n.t('allDay'),
    locale: 'fr',
    firstDay: 1,
    nowIndicator: true,
    selectable: true,
    slotDuration: '00:15:00',
    slotLabelInterval: { hours: 1 },
    scrollTime: '08:00:00',
    defaultTimedEventDuration: '00:15:00',
    weekends: false,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    moreLinkContent (arg) {
      return { html: arg.shortText + ' ' + i18n.tc('more', arg.num) }
    },

    eventClassNames ({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarEvent._def.extendedProps.calendar.color

      return [
        // Background Color
        `bg-light-${colorName} crossedOut`,
      ]
    },
    eventContent (arg) {
      return { domNodes: createContextMenu(arg.event, arg.timeText) }
    },
    eventClick ({ event: clickedEvent }) {
      emit('eventClick', grabEventDataFromEventApi(clickedEvent))
    },

    // customButtons: {
    //   sidebarToggle: {
    //     // --- This dummy text actual icon rendering is handled using SCSS ----- //
    //     text: 'sidebar',
    //     click () {
    //       // eslint-disable-next-line no-use-before-define
    //       isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
    //     },
    //   },
    // },

    dateClick (info) {
      // console.log(info)
      emit('dateClick', info)
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      // event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
    },

    select (selectionInfo) {
      // console.log(selectionInfo)
      emit('selectDateRange', selectionInfo)
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop ({ event: droppedEvent, revert }) {
      // console.log(droppedEvent)
      // console.log(clone(droppedEvent))

      if (droppedEvent.extendedProps.entityType != 'event') {
        revert()
      }

      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize ({ event: resizedEvent }) {
      // console.log(resizedEvent)
      // console.log(clone(resizedEvent))
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.getters['app/isRTL'] ? 'rtl' : 'ltr')),

    datesSet (dateInfo) {
      viewDates.value = {
        view: dateInfo.view.type,
        start: dateInfo.start,
        end: dateInfo.end
      }
    },

    eventDidMount: (arg) => {
      // console.log(arg)
      const eventId = arg.event.id
      arg.el.addEventListener('contextmenu', (jsEvent) => {
        jsEvent.preventDefault()

        let calendarContextMenus = document.getElementsByClassName('calendarContextMenu')
        for (let i = 0; i < calendarContextMenus.length; i++) {
          if (calendarContextMenus[i].getAttribute('id') == ('contextMenu' + eventId)) {
            let contextMenu = document.getElementById('contextMenu' + eventId)
            contextMenu.classList.remove('d-none')

            let container = contextMenu.parentElement.parentElement.parentElement.parentElement
            container.style.zIndex = '2'
          } else {
            let contextMenu = document.getElementById(calendarContextMenus[i].getAttribute('id'))
            contextMenu.classList.add('d-none')

            let container = contextMenu.parentElement.parentElement.parentElement.parentElement
            container.style.zIndex = '1'
          }
        }

      })
    },
  })

  const updateEvent = eventData => {
    // console.log(clone(eventData))
    emit('updateEventDates', eventData.id, eventData.startTmp, eventData.endTmp, eventData.extendedProps.entityType, eventData.allDay)
  }

  const hideContextsMenus = () => {
    let calendarContextMenus = document.getElementsByClassName('calendarContextMenu')
    for (let i = 0; i < calendarContextMenus.length; i++) {
      calendarContextMenus[i].classList.add('d-none')
    }
  }

  const createContextMenu = (argEvent, timeText) => {
    hideContextsMenus()
    // console.log(argEvent)
    let entityType = argEvent._def.extendedProps.entityType
    // console.log(entityType)

    let mainFrame = document.createElement('div')

    let detail = document.createElement('div')
    detail.classList.add('fc-event-main-frame')
    // detail.classList.add('text-truncate')

    let eventTime = document.createElement('div')
    eventTime.classList.add('fc-event-time')
    eventTime.innerHTML = timeText
    detail.appendChild(eventTime)

    let eventTitleContainer = document.createElement('div')
    eventTitleContainer.classList.add('fc-event-title-container')
    let eventTitleSticky = document.createElement('div')
    eventTitleSticky.classList.add('fc-event-title')
    eventTitleSticky.classList.add('fc-sticky')

    if (argEvent.extendedProps.calendar.icon) {
      let eventIcon = document.createElement('i')
      eventIcon.classList.add(argEvent.extendedProps.calendar.icon.library)
      eventIcon.classList.add('fa-' + argEvent.extendedProps.calendar.icon.icon)
      eventIcon.classList.add('mr-25')
      eventTitleSticky.appendChild(eventIcon)

      argEvent.extendedProps.calendar.additionalIcons.forEach(ai => {
        let eventAdditionalIcon = document.createElement('i')
        eventAdditionalIcon.classList.add(ai.library)
        eventAdditionalIcon.classList.add('fa-' + ai.icon)
        eventAdditionalIcon.classList.add('mr-25')
        eventTitleSticky.appendChild(eventAdditionalIcon)
      })

      let eventTitle = document.createElement('span')
      eventTitle.classList.add('ml-25')
      eventTitle.innerHTML = argEvent.title
      eventTitleSticky.appendChild(eventTitle)

      eventTitleContainer.appendChild(eventTitleSticky)
      detail.appendChild(eventTitleContainer)
    } else {
      argEvent.extendedProps.calendar.additionalIcons.forEach(ai => {
        let eventAdditionalIcon = document.createElement('i')
        eventAdditionalIcon.classList.add(ai.library)
        eventAdditionalIcon.classList.add('fa-' + ai.icon)
        eventAdditionalIcon.classList.add('mr-25')
        eventTitleSticky.appendChild(eventAdditionalIcon)
      })

      eventTitleSticky.innerHTML = argEvent.title
      eventTitleContainer.appendChild(eventTitleSticky)
      detail.appendChild(eventTitleContainer)
    }
    mainFrame.appendChild(detail)

    let menu = document.createElement('ul')
    menu.setAttribute('id', ('contextMenu' + argEvent.id))
    menu.setAttribute('tabindex', '-1')
    menu.setAttribute('role', 'menu')
    menu.setAttribute('aria-hidden', 'true')
    menu.classList.add('v-context')
    // menu.classList.add('position-relative')
    menu.classList.add('position-absolute')
    menu.classList.add('d-none')
    menu.classList.add('calendarContextMenu')
    menu.style.zIndex = '30'
    // menu.style.position = 'absolute!important'
    menu.style.top = '0'
    menu.style.right = '100%'

    let lis = ['edit', 'duplicate', 'magnetization', 'delete']

    lis.forEach(text => {
      if (
        entityType != 'reminder' ||
        (
          entityType == 'reminder' &&
          text != 'magnetization'
        )
      ) {
        let li = document.createElement('li')
        li.style.zIndex = '31'
        let a = document.createElement('a')
        a.setAttribute('href', '#')
        a.setAttribute('target', '_self')
        a.setAttribute('eventId', argEvent.id)
        a.classList.add('d-flex')
        a.classList.add('align-items-center')
        // if (
        //   // text == 'delete' &&
        //   argEvent._def.extendedProps.color == 'secondary'
        // ) {
        //   a.classList.add('disabled')
        // }
        a.addEventListener('click', (e) => {
          // if (argEvent._def.extendedProps.color != 'secondary') {
          if (text != 'edit') {
            e.preventDefault()
            e.stopPropagation()
          }
          if (text == 'duplicate') {
            if (entityType == 'event') {
              let event = store.getters['event3/getEvent'](argEvent.id)
              emit('duplicateEvent', event)
            } else if (entityType == 'reminder') {
              // let reminder = store.dispatch('event3/getEvent', argEvent.id)
              //   .then(response => {
              //
              //   })
              emit('duplicateReminder', { id: argEvent.id })
            }
          } else if (text == 'magnetization') {
            store.dispatch('event3/getEvent', argEvent.id)
              .then(response => {
                let lastEventBefore = getLastEventBefore(response.data.endDate, argEvent.id)
                let durationMinutes = moment.duration(moment(response.data.endDate).diff(moment(response.data.startDate))).minutes()

                if (lastEventBefore != null) {
                  response.data.startDate = moment(clone(lastEventBefore.endDate))
                  response.data.endDate = moment(clone(lastEventBefore.endDate)).add(durationMinutes, 'minutes')
                }

                store.dispatch('event3/updateEvent', response.data)
                  .then(response => {
                    emit('magnetizeNewEvent')
                  })
              })
          } else if (text == 'delete') {
            if (entityType == 'event') {
              let event = store.getters['event3/getEvent'](argEvent.id)
              emit('removeEvent', event)
            } else if (entityType == 'reminder') {
              emit('removeReminder', {id:argEvent.id})
            }

          }
          // } else {
          //   e.preventDefault()
          //   e.stopPropagation()
          // }
          menu.classList.add('d-none')
        })
        let span = document.createElement('span')
        span.classList.add('ml-75')
        span.innerHTML = capitalize(i18n.t(text))
        a.appendChild(span)
        li.appendChild(a)
        menu.appendChild(li)
      }

    })
    mainFrame.appendChild(menu)

    return [mainFrame]
  }

  const getLastEventBefore = (date, eventId) => {
    let searchedDate = moment(clone(date))

    let currentUserEvents = store.getters['event3/getEvents'].filter(e => {
      return e._calendarEvent.extendedProps.concernedUsers.some(u => u.id == getUserData().id) &&
        e.id != eventId &&
        moment(e.endDate).format('YYYY-MM-DD') == searchedDate.format('YYYY-MM-DD')
    })
    let lastEventBeforeLocal = null
    currentUserEvents.forEach(e => {
      if (
        moment(e.endDate).format('YYYY-MM-DD') == searchedDate.format('YYYY-MM-DD') &&
        (
          moment(e.endDate).isBefore(searchedDate) ||
          moment(e.endDate).format('HH:mm') == searchedDate.format('HH:mm')
          // searchedDate.isAfter(moment(e.endDate))
        ) &&
        (
          lastEventBeforeLocal == null ||
          moment(lastEventBeforeLocal.endDate).isBefore(moment(e.endDate))
        )
      ) {
        // console.log(clone(e))
        lastEventBeforeLocal = e
      }
    })

    return lastEventBeforeLocal
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    // console.log(eventApi)
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, entityType },
      allDay,
    } = eventApi

    let startTmp = moment(start).format('YYYY-MM-DD HH:mm')
    let endTmp = null
    // let allDayTmp = clone(allDay)

    if (eventApi.extendedProps.entityType == 'reminder') {
      // if (allDay == true) {
      //   endTmp = moment(clone(start)).add(15, 'minutes').format('YYYY-MM-DD HH:mm')
      // } else {
        endTmp = moment(clone(start)).add(15, 'minutes').format('YYYY-MM-DD HH:mm')
      // }
      // allDayTmp = true
    } else if (eventApi.extendedProps.entityType == 'event') {
      endTmp = moment(end).format('YYYY-MM-DD HH:mm')
    }

    // if (eventApi.extendedProps.entityType != 'event') {
    //   endTmp = moment(clone(start)).add(15, 'minutes').format('YYYY-MM-DD HH:mm')
    // } else {
    //   endTmp = moment(end).format('YYYY-MM-DD HH:mm')
    // }

    return {
      id,
      title,
      startTmp,
      endTmp,
      extendedProps: {
        calendar,
        entityType
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.getters['calendar2/getSelectedCalendars'])

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
    refetchEvents()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  // const calendarsColor = {
  //   Business: 'primary',
  //   Holiday: 'success',
  //   Personal: 'danger',
  //   Family: 'warning',
  //   ETC: 'info',
  // }

  return {
    viewDates,
    calendarOptions,
    fetchEvents,
    refCalendar,
    refetchEvents,

    // UI
    isCalendarOverlaySidebarActive,
    isEventHandlerSidebarActive,
  }
}