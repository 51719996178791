<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
          v-if="allowAdd"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          aria-controls="sidebar-add-new-event"
          :aria-expanded="String(isEventHandlerSidebarActive)"
          variant="primary"
          block
          @click="$emit('addEvent')"
      >
        {{ $t('AddEvent') }}
      </b-button>
      <div :class="{'mt-2': allowAdd}">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ capitalize($tc('calendar', 2)) }}</span>
        </h5>
        <b-form-checkbox
            v-model="checkAll"
            class="mb-2"
        >
          {{ capitalize($t('viewAll')) }}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
              v-model="selectedCalendars"
              name="event-filter"
              stacked
          >
            <b-form-checkbox
                v-for="item in calendarOptions"
                :key="item.label"
                name="event-filter"
                :value="item.label"
                class="mb-1"
                :class="['custom-control-'+item.color, 'text-'+item.color]"
            >
              <!--              <div :class="'text-'+item.color">-->
              <icon
                  :icon="item.icon.icon"
                  :library="item.icon.library"
              />
              {{ capitalize($tc(item.label, 2)) }}
              <!--              </div>-->
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>

      <slot name="calendarSidebar" />
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')"/>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useCalendarSidebar } from './useCalendarSidebar'

import Ripple from 'vue-ripple-directive'
import { capitalize } from '../../../utils/filter'

export default {
  directives: {
    Ripple,
  },
  components: {},
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    allowAdd: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const {
      calendarOptions,
      checkAll,
      selectedCalendars,
    } = useCalendarSidebar()

    return {
      capitalize,
      calendarOptions,
      checkAll,
      selectedCalendars,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>