<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar
            :allow-add="allowAdd"
            :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"

            @addEvent="$emit('addEvent')"
        >
          <template #calendarSidebar>
            <slot name ="calendarSidebar" />
          </template>
        </calendar-sidebar>
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
      />

    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'

import CalendarSidebar from './calendarSidebar/CalendarSidebar.vue'
import FullCalendar    from '@fullcalendar/vue'
import { useCalendar } from './useCalendar'

export default {
  components: {
    CalendarSidebar,
    FullCalendar
  },
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const {
      viewDates,
      calendarOptions,
      fetchEvents,
      refetchEvents,
      refCalendar,
      isCalendarOverlaySidebarActive,
      isEventHandlerSidebarActive,
    } = useCalendar(emit)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(viewDates, val => {
      emit('calendarDatesChange', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchEvents()

    return {
      calendarOptions,
      refCalendar,
      fetchEvents,
      refetchEvents,

      // UI
      isCalendarOverlaySidebarActive,
      isEventHandlerSidebarActive,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/base/pages/app-calendar.scss';
</style>