<template>
  <ul class="timeline">
    <slot />
  </ul>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss"
       scoped>
.timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-left: 1rem;
}
</style>