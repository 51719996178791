<template>
  <div>
    <calendar
        ref="calendarRef"

        @fetchEvents="fetchEvents"
        @calendarDatesChange="calendarDatesChange"
        @addEvent="addEvent"
        @duplicateEvent="duplicateEvent($event)"
        @duplicateReminder="$emit('duplicateReminder', $event)"
        @magnetizeNewEvent="reFetchEvents"
        @eventClick="eventClick"
        @updateEventDates="updateEventDates"
        @dateClick="dateClick"
        @selectDateRange="selectDateRange"
        @removeEvent="removeEventAlert($event)"
        @removeReminder="$emit('removeReminder', $event)"
    >
      <template #calendarSidebar>
        <field-select
            :model.sync="selectedUserCalendars"
            :multiple="true"
            :options="autocompleteEmployees"
            name="employee"
        />
      </template>
    </calendar>

    <!-- modal event-->
    <modal-event
        :event.sync="currentEvent"
        :title="eventModalTitle"
        :isOpen.sync="eventModalShow"
        :view-only="!isEditable"
        @submitValidatedEvent="submitValidatedEventLocal"
        @removeEvent="removeEventAlert"
    />
  </div>
</template>

<script>
import { ref, computed, watch }             from '@vue/composition-api'
import FieldSelect                          from '../input/Select2.vue'
import Calendar                             from '../calendar2/Calendar.vue'
import ModalEvent                           from '../prompt/Event.vue'
import moment                               from 'moment'
import { arrayUtcToTz, clone, getUserData } from '../../utils/utils'
import i18n                                 from '../../libs/i18n'
import useAPI                               from '../../utils/useAPI'
import { useEvents }                        from './useEvents'
import store                                from '../../store'
import { capitalize }                       from '../../utils/filter'
import router                               from '../../router'
import Vue                                  from 'vue'
import ToastificationContent                from '../toastification/ToastificationContent.vue'

export default {
  components: { ModalEvent, Calendar, FieldSelect },
  props: {
    mandatoryFilter: {
      type: Array,
      default: () => []
    },
    byStore: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const calendarRef = ref(null)
    const selectedUserCalendars = ref([{ id: getUserData().id, _display: getUserData()._display }])
    const startDate = ref(null)
    const endDate = ref(null)

    const currentEvent = ref({})
    const eventModalShow = ref(false)
    const eventModalTitle = ref(i18n.t('NewEvent'))

    const localEvents = ref([])
    const localReminders = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteEmployees } = useAPI()

    const isEditable = computed(() => {
      return (
              currentEvent.value.isValidated != true
          ) &&
          (
              (
                  !('id' in currentEvent.value)
              ) ||
              (
                  'logs' in currentEvent.value &&
                  currentEvent.value.logs.length >= 1 &&
                  currentEvent.value.logs[0].by.id == getUserData().id
              )
          )

    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedUserCalendars, val => {
      reFetchEvents()
    })

    watch(eventModalShow, val => {
      if (val == false) {
        resetEvent()
      }
    })

    // watch(currentEvent, val => {
    //   console.log(val)
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedEvent, removeEvent } = useEvents()

    const updateCalendar = () => {
      calendarRef.value.refetchEvents()
    }

    const fetchEvents = (selectedCalendars, info, successCallback) => {
      let events = []
      let reminders = []

      if (props.byStore == true) {
        if ('workFloId' in router.history.current.params) {
          events = store.getters['event3/getCalendarEvents'].filter(event => {
            return selectedCalendars.includes(event.extendedProps.calendar.label) &&
                event.extendedProps.workFlo == router.history.current.params.workFloId
          })

          reminders = store.getters['reminder/getCalendarReminders'].filter(reminder => {
            return selectedCalendars.includes(reminder.extendedProps.calendar.label) &&
                reminder.extendedProps.workFlo == router.history.current.params.workFloId
          })
        } else {
          events = store.getters['event3/getCalendarEvents'].filter(event => selectedCalendars.includes(event.extendedProps.calendar.label))
          reminders = store.getters['reminder/getCalendarReminders'].filter(reminder => selectedCalendars.includes(reminder.extendedProps.calendar.label))
        }
      } else {
        if ('workFloId' in router.history.current.params) {
          events = localEvents.value.filter(event => {
            return selectedCalendars.includes(event.extendedProps.calendar.label) &&
                event.workFlo != null &&
                event.workFlo.id == router.history.current.params.workFloId
          })

          reminders = localReminders.value.filter(reminder => {
            return selectedCalendars.includes(reminder.extendedProps.calendar.label) &&
                reminder.workFlo != null &&
                reminder.workFlo.id == router.history.current.params.workFloId
          })

        } else {
          events = localEvents.value.filter(event => selectedCalendars.includes(event.extendedProps.calendar.label))
          reminders = localReminders.value.filter(reminder => selectedCalendars.includes(reminder.extendedProps.calendar.label))

        }

      }

      // console.log([...events, ...reminders])
      successCallback([...events, ...reminders])
    }

    const reFetchEvents = () => {
      let eventDateFilter = [{
        sectionLogicOperator: 'AND',
        sectionFilters: [
          {
            logicOperator: 'AND',
            field: 'startDate',
            operator: 'between',
            value: [startDate.value, endDate.value]
          },
        ]
      }]

      let employeesFilters = [{
        sectionLogicOperator: 'AND',
        sectionFilters: []
      }]
      selectedUserCalendars.value.forEach(u => {
        employeesFilters[0].sectionFilters.push({
          logicOperator: 'OR',
          field: 'createdBy',
          operator: 'equal',
          value: u.id
        }, {
          logicOperator: 'OR',
          field: 'additionalMembers',
          operator: 'equal',
          value: u.id
        })
      })

      let reminderDateFilter = [{
        sectionLogicOperator: 'AND',
        sectionFilters: [
          {
            logicOperator: 'AND',
            field: 'completionDate',
            operator: 'between',
            value: [startDate.value, endDate.value]
          },
        ]
      }]

      let reminderFilter = [{
        sectionLogicOperator: 'AND',
        sectionFilters: [
          {
            logicOperator: 'AND',
            field: 'isClosed',
            operator: 'equal',
            value: false
          },
        ]
      }]

      store.dispatch('event3/getEvents', {
        // filters: dateFilter
        filters: [...eventDateFilter, ...employeesFilters, ...props.mandatoryFilter],
        per_page: 5000
      }).then(response => {
        // console.log(response.data)

        if (props.byStore == false) {
          arrayUtcToTz(response.data.results)

          localEvents.value = []
          response.data.results.forEach(e => {
            localEvents.value.push(e._calendarEvent)
          })
        }

        store.dispatch('reminder/getReminders', {
          payload: {
            filters: [...reminderFilter, ...reminderDateFilter, ...employeesFilters, ...props.mandatoryFilter],
            // filters: [...reminderDateFilter, ...employeesFilters, ...props.mandatoryFilter],
            per_page: 5000
          },
          options: {
            saveToStore: props.byStore
          }
        }).then(response => {
          if (props.byStore == false) {
            arrayUtcToTz(response.data.results)

            localReminders.value = []
            response.data.results.forEach(e => {
              localReminders.value.push(e._calendarEvent)
            })
          }

          updateCalendar()
        })

      })
    }

    const calendarDatesChange = (dates) => {
      startDate.value = dates.start
      endDate.value = dates.end

      reFetchEvents()
    }

    const resetEvent = () => {
      currentEvent.value = clone(store.getters['event3/getEmptyEvent'])
      eventModalTitle.value = i18n.t('NewEvent')
      // console.log(clone(currentEvent.value))
    }

    const addEvent = () => {
      // console.log('addEvent')
      resetEvent()

      if ('workFloId' in router.history.current.params) {
        currentEvent.value.workFlo = {
          id: router.history.current.params.workFloId
        }
      }

      currentEvent.value.startDate = moment()
      currentEvent.value.endDate = moment().add(30, 'minutes')

      eventModalShow.value = true
    }

    const eventClick = (calendarEvent) => {
      if (calendarEvent.extendedProps.entityType == 'event') {
        store.dispatch('event3/getEvent', calendarEvent.id)
            .then(response => {
              eventModalTitle.value = i18n.t('EditEvent')

              currentEvent.value = response.data
              eventModalShow.value = true
            })
      } else if (calendarEvent.extendedProps.entityType == 'reminder') {
        emit('editReminder', calendarEvent)
      }
    }

    const updateEventDates = (entityId, newStart, newEnd, entityType, isAllDay) => {
      // console.log(entityType)
      if (entityType == 'event') {
        store.dispatch('event3/getEvent', entityId)
            .then(response => {
              currentEvent.value = response.data
              currentEvent.value.startDate = moment(newStart).format('YYYY-MM-DD HH:mm:ss')
              currentEvent.value.endDate = moment(newEnd).format('YYYY-MM-DD HH:mm:ss')

              submitValidatedEventLocal()
            })
      } else if (entityType == 'reminder') {
        store.dispatch('reminder/getReminder', entityId)
            .then(response => {
              arrayUtcToTz([response.data])

              if (isAllDay == true) {
                response.data.completionDate = moment(response.data.completionDate)
                    .year(moment(newStart).format('YYYY'))
                    .month(moment(newStart).format('M') - 1)
                    .date(moment(newStart).format('D'))
                response.data._calendarEvent.start = response.data.completionDate

                store.dispatch('reminder/updateReminder', response.data)

                if (props.byStore == false) {
                  let index = localReminders.value.findIndex(i => i.id == entityId)
                  localReminders.value.splice(index, 1, clone(response.data)._calendarEvent)
                }
                updateCalendar()
              } else {
                currentEvent.value = reminderToEvent(response.data)
                currentEvent.value.startDate = moment(newStart).format('YYYY-MM-DD HH:mm:ss')
                currentEvent.value.endDate = moment(newEnd).format('YYYY-MM-DD HH:mm:ss')
                currentEvent.value.reminder.isClosed = true

                eventModalShow.value = true
              }

            })
      } else {
        console.log('Entity type not allowed to update')
      }
    }

    const populateCurrentEventFromCalendarEvent = (calendarEvent) => {
      currentEvent.value.endDate = calendarEvent.end
      currentEvent.value.isAllDay = calendarEvent.allDay
      currentEvent.value.startDate = calendarEvent.start
    }

    const dateClick = (calendarEvent) => {
      calendarEvent.start = moment(calendarEvent.date).toJSON()
      calendarEvent.end = moment(calendarEvent.date).add(15, 'minutes').toJSON()

      populateCurrentEventFromCalendarEvent(calendarEvent)

      if ('workFloId' in router.history.current.params) {
        currentEvent.value.workFlo = {
          id: router.history.current.params.workFloId
        }
      }

      // eventModalShow.value = true
      // console.log(clone(currentEvent.value))
    }

    const selectDateRange = (calendarEvent) => {
      // console.log('selectDateRange', calendarEvent)

      populateCurrentEventFromCalendarEvent(calendarEvent)

      if ('workFloId' in router.history.current.params) {
        currentEvent.value.workFlo = {
          id: router.history.current.params.workFloId
        }
      }

      if (calendarEvent.allDay == true) {
        let reminder = eventToReminder(currentEvent.value)
        reminder.completionDate = calendarEvent.startStr
        reminder.type = 'work'

        // console.log(reminder)
        emit('addReminder', reminder)
      } else {
        eventModalShow.value = true
      }

    }

    const duplicateEvent = (eventToDuplicate) => {
      store.dispatch('event3/getEvent', eventToDuplicate.id)
          .then(response => {
            currentEvent.value = clone(response.data)
            delete currentEvent.value.id
            delete currentEvent.value.logs
            currentEvent.value.mileageAllowanceCost = 0

            let duration = moment.duration(moment(eventToDuplicate.endDate).diff(moment(eventToDuplicate.startDate)))
            currentEvent.value.startDate = moment(clone(eventToDuplicate.endDate))
            currentEvent.value.endDate = moment(clone(eventToDuplicate.endDate)).add(duration)

            submitValidatedEventLocal()
          })
    }

    const submitValidatedEventLocal = () => {
      if (isEditable.value == true) {
        //console.log(clone(currentEvent.value))

        let reminder = null
        if (
            ('_reminderPeriod' in currentEvent.value) &&
            currentEvent.value._reminderPeriod != null
        ) {
          reminder = eventToReminder(currentEvent.value)

          //console.log(reminder)
          delete reminder.closeReminder
          delete reminder.writtenEmail

          // emit('addReminder', eventToReminder(currentEvent.value))
        }

        submitValidatedEvent(currentEvent.value)
            .then(response => {
              if (props.byStore == false) {
                if ('id' in currentEvent.value) {
                  let index = localEvents.value.findIndex(i => i.id == response.data._calendarEvent.id)
                  localEvents.value.splice(index, 1, JSON.parse(JSON.stringify(response.data._calendarEvent)))
                } else {
                  localEvents.value.push(response.data._calendarEvent)
                }

                if (
                    ('closeReminder' in currentEvent.value) &&
                    currentEvent.value.closeReminder == true &&
                    currentEvent.value.reminder != null
                ) {
                  localReminders.value = localReminders.value.filter(item => item.id != response.data.reminder.id)
                }

              } else {
                if (
                    ('closeReminder' in currentEvent.value) &&
                    currentEvent.value.closeReminder == true &&
                    currentEvent.value.reminder != null
                ) {
                  store.commit('reminder/REMOVE_REMINDER', response.data.reminder)
                }
              }

              if (reminder != null) {
                emit('addReminder', reminder)
              }

              updateCalendar()

              eventModalShow.value = false
              resetEvent()
            })
      } else {
        Vue.$toast({
          component: ToastificationContent, props: {
            title: capitalize(i18n.t('error')),
            icon: 'times',
            text: i18n.t('You are not authorized to perform this action'),
            variant: 'danger'
          },
        })
      }
    }

    const removeEventLocal = () => {
      removeEvent(currentEvent.value)
          .then(response => {
            // if (getRoute().name == 'Event view') {
            //   router.push({ name: 'Events' })
            // }
            if (props.byStore == false) {
              localEvents.value = localEvents.value.filter(item => item.id != currentEvent.value.id)
            }

            eventModalShow.value = false
            updateCalendar()
            resetEvent()
          })
    }

    const reminderToEvent = (reminder) => {
      let event = clone(reminder)

      delete event.id
      delete event.logs
      delete event.completionDate
      delete event.isClosed
      delete event.isOutgoingCall
      delete event.priority
      event.mileageAllowanceCost = 0
      event.outgoingCall = reminder.isOutgoingCall
      event.reminder = clone(reminder)

      return event
    }

    const eventToReminder = (event) => {
      let reminder = clone(event)

      // let startDate = moment(clone(event.startDate))
      // let hours = startDate.format('H')
      // let minutes = startDate.format('m')

      delete reminder.id
      delete reminder.logs
      delete reminder.mileageAllowanceCost
      delete reminder.outgoingCall
      delete reminder.startDate
      delete reminder.endDate
      delete reminder.isAllDay
      delete reminder.isValidated
      delete reminder.reminder
      delete reminder.callResultType
      delete reminder.isOutgoingCall
      delete reminder.distance

      reminder.priority = 'low'
      // reminder.completionDate = moment(clone(event._reminderPeriod)).hour(hours).minute(minutes)
      reminder.completionDate = moment(clone(event._reminderPeriod)).format('YYYY-MM-DD')
      reminder.type = event._reminderType

      return reminder
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetEvent()

    store.commit('calendar2/SET_SELECTED_CALENDARS', ['work', 'discussion', 'call', 'mail', 'intervention', 'trip', 'absence'])
    store.commit('calendar2/SET_CALENDAR_OPTIONS', [
      {
        color: 'primary',
        label: 'work',
        icon: {
          icon: 'user-circle',
          library: 'fas'
        }
      },
      {
        color: 'danger',
        label: 'discussion',
        icon: {
          icon: 'comments',
          library: 'fas'
        }
      },
      {
        color: 'success',
        label: 'call',
        icon: {
          icon: 'phone',
          library: 'fas'
        }
      },
      {
        color: 'info',
        label: 'mail',
        icon: {
          icon: 'envelope',
          library: 'fas'
        }
      }, {
        color: 'secondary',
        label: 'intervention',
        icon: {
          icon: 'briefcase',
          library: 'fas'
        }
      }, {
        color: 'warning',
        label: 'trip',
        icon: {
          icon: 'suitcase-rolling',
          library: 'fas'
        }
      }, {
        color: 'danger',
        label: 'absence',
        icon: {
          icon: 'glasses',
          library: 'fas'
        }
        // }, {
        //   color: 'secondary',
        //   label: 'other',
        //   icon: {
        //     icon: 'question-circle',
        //     library: 'fas'
        //   }
      },
    ])

    return {
      // Components
      capitalize,

      // Data
      calendarRef,
      selectedUserCalendars,

      currentEvent,
      eventModalShow,
      eventModalTitle,

      // Computed
      autocompleteEmployees,
      isEditable,

      // Methods
      fetchEvents,
      calendarDatesChange,
      addEvent,
      eventClick,
      updateEventDates,
      dateClick,
      selectDateRange,
      duplicateEvent,
      submitValidatedEventLocal,
      removeEventLocal,
      reFetchEvents,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    removeEventAlert (event = null) {
      if (event != null) {
        this.currentEvent = event
      }

      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theEvent') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeEventLocal()
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>