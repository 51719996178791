<template>
  <div>

    <b-row>
      <b-col md="8">
        <events-calendar
            ref="eventsCalendarRef"
            :by-store="false"

            @addReminder="addReminder($event)"
            @editReminder="editReminder($event)"
            @duplicateReminder="duplicateReminder($event)"
            @removeReminder="removeReminder($event)"
        />
      </b-col>

      <b-col md="4">
        <reminder
            ref="reminderRef"
            :reminders="reminders"

            @updateCalendar="updateCalendar"
            @updateReminders="updateReminders"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted } from '@vue/composition-api'
import useAPI                                from '../../utils/useAPI'
import EventsCalendar                        from '../../components/eventsCalendar/EventsCalendar.vue'
import Reminder                              from '../../components/reminder/Reminder.vue'
import { getUserData }                       from '../../utils/utils'
import store                                 from '../../store'
import { useEmails }                         from '../../components/email/v2/useEmails'
import ability                               from '../../libs/acl/ability'

export default {
  components: { EventsCalendar, Reminder },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const reminders = ref({})
    const eventsCalendarRef = ref(null)
    const reminderRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchAutocompleteEmployees,
      fetchAutocompleteWorkflows,
      fetchAutocompleteWorkFlos,
      fetchAutocompleteUsers
    } = useAPI()

    const { refreshEmails } = useEmails()

    const addReminder = reminder => {
      reminderRef.value.addExternalReminder(reminder)
    }

    const editReminder = reminder => {
      reminderRef.value.editExternalReminder(reminder)
    }

    const duplicateReminder = reminder => {
      reminderRef.value.duplicateReminder(reminder)
    }

    const removeReminder = reminder => {
      reminderRef.value.removeReminderAlert(reminder)
    }

    const updateCalendar = () => {
      // console.log(eventsCalendarRef.value)

      //To do : faire mieux...
      eventsCalendarRef.value.reFetchEvents()
    }

    const updateReminders = () => {
      store.dispatch('user2/getUserReminders', getUserData().id)
          .then(response => {
            // console.log(response)
            reminders.value = response.data
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    let interval
    // if (getUserData().roles.includes('ROLE_ADMIN')) {
    if (ability.can('manage', 'Emails')) {
      onUnmounted(() => {
        clearInterval(interval)
      })
    }

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchAutocompleteEmployees()
    fetchAutocompleteWorkflows()
    fetchAutocompleteWorkFlos()
    fetchAutocompleteUsers()

    updateReminders()

    // if (getUserData().roles.includes('ROLE_ADMIN')) {
    if (ability.can('manage', 'Emails')) {
      console.log('refresh from dashboard')
      refreshEmails()

      interval = setInterval(() => {
        console.log('refresh from dashboard')
        refreshEmails()
      }, 2 * 60000)
    }

    return {
      // Components

      // Data
      reminders,
      eventsCalendarRef,
      reminderRef,

      // Computed

      // Methods
      addReminder,
      editReminder,
      duplicateReminder,
      updateCalendar,
      updateReminders,
      removeReminder,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () { },
  created () { }
}
</script>

<style
    scoped
    lang="scss"
>

</style>